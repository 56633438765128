import * as actions from '../redux/socket/socket';
import io, { Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { Middleware } from '@reduxjs/toolkit';
import { apiSlice } from '../redux/apiSlice';

type tagTypes = "Deals" | "Customers" | "Account" | "Catalog" | "Permissions" | "Product" |
    "Cards" | "DealTypes" | "Decks" | "ExtraDealTypes"

const enhancedApi = apiSlice.enhanceEndpoints({
    addTagTypes: ["Deals", "Customers", "Account", "Catalog", "Permissions", "Product",
        "Cards", "DealTypes", "Decks", "ExtraDealTypes" ],
  });

const socketMiddleware = (): Middleware => {
    let socket: Socket<DefaultEventsMap, DefaultEventsMap> | null = null;

    return store => next => action => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const invalidate = (list: boolean, res: any, type: tagTypes): void => {
            const authStatus = store.getState().clients.status;
            if (authStatus === 'succeeded') {
                if (list) {
                    store.dispatch(enhancedApi.util.invalidateTags([{ type: type, id: 'LIST' }]))
                } else {
                    store.dispatch(enhancedApi.util.invalidateTags([{ type: type, id: res.id }]))
                }
            }
        }    

        switch (action.type) {
            case "SC_CONNECT":

                if (socket !== null) {
                    socket.close();
                }
                socket = io(action.host);

                socket.on("disconnect", () => {
                    store.dispatch(actions.socketDisconnected())
                });

                socket.on("connect", () => {
                    console.log('socket open', socket?.id);
                    store.dispatch(actions.socketConnected(socket?.id))
                });

                socket.on('hello', (res) => {
                    console.log(res)
                })

                socket.on('card_created', (res) => { invalidate(false, res, "Cards") });

                socket.on('cards_created', () => { invalidate(true, null, "Cards") });

                socket.on('card_updated', (res) => { invalidate(false, res, "Cards") });

                socket.on('card_deleted', (res) => { invalidate(false, res, "Cards") });

                socket.on('deal_types_created', () => { invalidate(false, null, "DealTypes") });

                socket.on('deal_type_created', (res) => { invalidate(false, res, "DealTypes") });

                socket.on('deal_type_updated', (res) => { invalidate(false, res, "DealTypes") });

                socket.on('deal_type_deleted', (res) => { invalidate(false, res, "DealTypes") });

                socket.on('decks_created', () => { invalidate(false, null, "Decks") });

                socket.on('deck_created', (res) => { invalidate(false, res, "Decks") });

                socket.on('deck_updated', (res) => { invalidate(false, res, "Decks") });

                socket.on('deck_deleted', (res) => { invalidate(false, res, "Decks") });

                socket.on('extra_deal_types_created', () => { invalidate(false, null, "ExtraDealTypes") });

                socket.on('extra_deal_type_created', (res) => { invalidate(false, res, "ExtraDealTypes") });

                socket.on('extra_deal_type_updated', (res) => { invalidate(false, res, "ExtraDealTypes") });

                socket.on('extra_deal_type_deleted', (res) => { invalidate(false, res, "ExtraDealTypes") });

                socket.on('customer_updated', (res) => { invalidate(false, res, "Customers") });

                socket.on('customer_created', (res) => { invalidate(false, res, "Customers") });

                socket.on('customer_deleted', (res) => { invalidate(false, res, "Customers") });

                socket.on('deal_updated', (res) => { invalidate(false, res, "Deals") });

                socket.on('deal_created', (res) => { invalidate(false, res, "Deals") });

                socket.on('deal_deleted', (res) => { invalidate(false, res, "Deals") });

                socket.on('user_created', () => {
                    const authStatus = store.getState().clients.status;
                    if (authStatus === 'succeeded') {
                        store.dispatch(enhancedApi.util.invalidateTags([
                            { type: 'Account' },
                            { type: 'Catalog' },
                            { type: 'Permissions' },
                            { type: 'Product' }
                        ]))
                    }
                });

                socket.on('user_updated', () => {
                    const authStatus = store.getState().settings.authenticated;
                    if (authStatus === 'succeeded') {
                        store.dispatch(enhancedApi.util.invalidateTags([
                            { type: 'Account' },
                            { type: 'Catalog' },
                            { type: 'Permissions' },
                            { type: 'Product' }
                        ]))
                    }
                });

                break;
            case "SC_DISCONNECT":

                if (socket !== null) {
                    socket.close();
                }

                socket = null;
                console.log('socket closed');

                break;
            case "SC_AUTH":
                if (socket != null && store.getState().settings.keycloak != null) {
                    const username = store.getState().settings.keycloak?.userInfo?.sub;
                    socket.emit("join", { user: username, room: username })
                }

                break;
            default:
                return next(action);        
        }
    };
};

export default socketMiddleware;